import React, { ReactNode } from 'react';
import {default as MuiFade} from '@material-ui/core/Fade';

type Props = {
    children: ReactNode;
}

const Fade = ({children}: Props) => //children
(
    <MuiFade in timeout={{enter: 1000}}>
        <div>
             {children}
        </div>
    </MuiFade>
)

export default Fade;