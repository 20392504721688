import React, { useMemo, useRef } from "react";
import { useStore, useActions } from "store";
import { QueryResult } from "@apollo/react-common";
import { useQuery } from "@apollo/react-hooks";
import { MY_ACCOUNT } from "graphql/queries/myAccount";
import { myAccount } from "graphql/generated/types/MyAccount";
import log from "utils/log";

type Props = {
  children: JSX.Element;
};

const ApolloAuth = ({ children }: Props) => {
  const loginSucceed = useActions(actions => actions.auth.loginSucceed);
  const logoutStore = useActions(actions => actions.auth.logout);
  const isAuthenticated = useStore(state => state.auth.isAuthenticated);
  const init = useRef(false);

  const { data, error, loading, client }: QueryResult<myAccount> = useQuery(MY_ACCOUNT, {
    fetchPolicy: "network-only",
  });
  if(!loading && !init.current && !error){
    if (data?.myAccount) {
      loginSucceed(data.myAccount);
    } else if (isAuthenticated) {
      client?.clearStore();
      logoutStore();
    }

    init!.current = true;
  }

  return children;
};

export default ApolloAuth;
