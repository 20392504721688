import React, { ReactNode, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";
import FooterContent from "components/templates/FooterContent";
import HeaderContent from "components/templates/HeaderContent";
import MenuBar from "components/templates/MenuBar";
import BodyContent from "components/templates/BodyContent";
import LeftContent from "components/templates/LeftContent";
import { useStore } from "store";
import Helmet from "react-helmet";
import Toolbar from "@material-ui/core/Toolbar";
import SideBar from "components/templates/Sidebar";

import "components/templates/sidebar.css"; 
import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import { motion, useViewportScroll, useTransform } from "framer-motion";

type Props = {
  children: ReactNode;
};

const MasterPage = ({ children }: Props) => {
  const { rightSidebar, leftSidebar, footer, fixedHeader } = useStore(
    state => state.layout.layout
  );
  //const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <StyledContainer>
      <CssBaseline />

      <Helmet>
        <html lang="it" />
        <title>Codecampus</title>
        <meta property="og:title" content="Codecampus" />
        {/* <link rel="canonical" href="localhost:3000" /> */}
      </Helmet>

{/*
      <StyledAppBar position={fixedHeader ? "fixed" : "relative"}>
        <Toolbar>
          <HeaderContent />
        </Toolbar> 
</StyledAppBar> */}

       <SideBar right  pageWrapId={"page-wrap"} outerContainerId={"root"} />
   

      <StyledFlexContainer id="page-wrap">
      <MenuBar />
      
        {/* left */}
        {leftSidebar && (
          <StyledDrawer variant="permanent" anchor="left">
            <ToolbarSpace />
            <LeftContentSpring />
          </StyledDrawer>
        )}

        <StyledContent>
          {fixedHeader && <ToolbarSpace />}
          <StyledMain id="#content">
            {/* <StyledToolbar /> */}
            <BodyContent>{children}</BodyContent>
          </StyledMain>

          {footer && (
            <StyledFooter
              style={{ width: !rightSidebar ? "auto" : "66%" }}
              id="footer"
            >
              <FooterContent />
            </StyledFooter>
          )}
        </StyledContent>
      </StyledFlexContainer>

      <ToastContainer />
    </StyledContainer>
  );
};

const drawerWidth = 240;

const StyledContainer = styled.div`
  overflow-x: hidden;
`;

const StyledFlexContainer = styled.div`
  display: flex;
`;

const StyledAppBar = styled(AppBar)`
  z-index: ${p => p.theme.zIndex.drawer + 1};
  color: black;
  background-color: ${p => p.theme.palette.background.paper};
`;

const StyledContent = styled.div`
  flex-grow: 1;
  max-width: 100%;
`;

const StyledMain = styled.div`
  flex: 0 0 100%;
  min-height: 400px;
`;

const ToolbarSpace = styled.div(p => p.theme.mixins.toolbar as any);

const LeftContentSpring = () => {
  const [elementTop, setElementTop] = React.useState(0);
  const ref = React.useRef<any>(null);
  const { scrollY } = useViewportScroll();

  useLayoutEffect(() => {
    const element = ref.current;
    if (element != null) setElementTop(element.offsetTop);
  }, [ref]);

  const y = useTransform(
    scrollY,
    [0, elementTop, elementTop],
    [0, -elementTop, -elementTop],
    {
      clamp: false
    }
  );

  return (
    <motion.div ref={ref} style={{ y }}>
      <LeftContent />
    </motion.div>
  );
};

const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  & .MuiPaper-root {
    width: ${drawerWidth}px;
  }
`;

const StyledFooter = styled.footer`
  /* flex: 0 0 100%; */
  background:#222;
  flex-grow: 1;
  padding: ${p => p.theme.spacing(2)}px;
  ${p => p.theme.breakpoints.up("sm")} {
    padding: ${p => p.theme.spacing(3)}px;
  }
  @media (min-width: 1400px) {
    padding: ${p => p.theme.spacing(5)}px;
  }
  transition: ${p =>
    p.theme.transitions.create(["margin"], {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })};
`;

export default MasterPage;
