export const MAX_FILE_SIZE = 1000000000;


export const Url = {
  // Admin: {
  //   admin: "/admin",
  //   user: ["/admin/users/", "id"],
  //   course: ["/admin/courses/", "id"],
  //   school: ["/admin/schools/", "id"]
  // },
  Topic: {
    view: ["/topic/", "topic"]
  },
  Lesson: {
    view: ["/lesson/", "lesson"]
  },
  Course: {
    view: ["/course/", "course"],
    Edit: {
      edit: ["/edit-course/", "course"],
      landing: ["/edit-course/landing/", "course"],
      lessons: ["/edit-course/lessons/", "course"]
    },
    search: "/course/search/",
    Manage: {
      all: "/courses/",
      iteach: "/courses/iteach/",
    }
  },
  School: {
    view: ["/school/", "school"],
    edit: ["/edit-school/", "school"],
    table: "/schools/"
  },
  ClassRoom: {
    view: ["/classroom/", "classroom"],
    edit: ["/edit-classroom/", "classroom"],
    table: "/classrooms/"
  },
  Chat: {
    view: "/chat/",
  },
  home: "/",
  Iscrizioni: {
    insegnanti: "/iscrizione/iscrizione-insegnanti",
    studenti: "/iscrizione/iscrizione-studenti",
    scuole: "/iscrizione/iscrizione-scuole"
  },
  User: {
    profile: "/user/profile",
    create: "/user/create",
    view: ["/user/", "user"],
    edit: ["/edit-user/", "user"],
    table: "/users/"
  },
  Student: {
    profile: "/student/profile",
    create: "/student/create",
    view: ["/student/", "student"],
    edit: ["/edit-student/", "student"],
    table: "/students/"
  },
  Teacher: {
    create: "/teacher/create",
    view: ["/teacher/", "teacher"],
    edit: ["/edit-teacher/", "teacher"],
    table: "/teachers/"
  },
  login: "/login",
  Projects: "/progetti-attivi",
  Contacts: "/contacts",
  privacy: "/privacy-policy",
  cookie: "/7842045/cookie-policy",
  Test: {
    test: "/test"
  }
};

export type UrlType = typeof Url;

// export const Url = {
//     admin: "/admin",
//     topic: "/topic/",
//     lesson: "/lesson/",
//     course: "/course/",
//     test: "/test",
//     editcourse: "/editcourse/",
//     home: "/",
//     login: "/login",
//     privacy: "/privacy-policy",
//     cookie: "/7842045/cookie-policy",
//     iscrizioni: {
//       insegnanti: "/iscrizione/iscrizione-insegnanti",
//       studenti: "/iscrizione/iscrizione-studenti",
//       scuole: "/iscrizione/iscrizione-scuole"
//     },
//     Sections: {
//       admin: {
//         users: "/users",
//         schools: "/schools"
//       },
//       editcourse: {
//         landing: "/landing",
//         lessons: "/lessons"
//       },
//       lesson: {
//         lesson: "lesson"
//       }
//     },
//     Params: {
//       course: "course",
//       lesson: "lesson",
//       topic: "topic",
//       id: "id"
//     }
//   };
