/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FeedbackTypeEnum {
  BUG = "BUG",
  INFO = "INFO",
}

export enum LessonType {
  QUIZ = "QUIZ",
  SLIDE = "SLIDE",
  STREAMING = "STREAMING",
  VIDEO = "VIDEO",
}

export enum OrderType {
  ABC = "ABC",
  POPULAR = "POPULAR",
  RECENT = "RECENT",
}

export enum QuestionType {
  DRAG_AND_DROP = "DRAG_AND_DROP",
  MULTIPLE = "MULTIPLE",
  SINGLE = "SINGLE",
}

export enum RolesEnum {
  ADMIN = "ADMIN",
  SCHOOL_REFERENCE = "SCHOOL_REFERENCE",
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
}

export enum SkillEnum {
  ESPERTO = "ESPERTO",
  PRINCIPIANTE = "PRINCIPIANTE",
  TUTTI = "TUTTI",
}

export enum WeekDayEnum {
  FRIDAY = "FRIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

export interface ChangePassword {
  confirmPassword?: string | null;
  newPassword?: string | null;
  oldPassword?: string | null;
}

export interface CheckQuiz {
  answers?: (QuizAnswer | null)[] | null;
  quizId: any;
}

export interface CreateClassRoom {
  name: string;
  schoolId: any;
}

export interface CreateComment {
  content: string;
  courseId: any;
}

export interface CreateCourse {
  name: string;
}

export interface CreateCurriculum {
  url?: string | null;
  userId: any;
}

export interface CreateFeedback {
  content: string;
  type: FeedbackTypeEnum;
  userId: any;
}

export interface CreateLesson {
  courseId: any;
  name: string;
  type: LessonType;
}

export interface CreateMenuVoice {
  model?: string | null;
  name: string;
  parentId: any;
  url?: string | null;
}

export interface CreateMessage {
  content?: string | null;
  conversationId?: any | null;
  lessonId: any;
}

export interface CreatePercorso {
  name: string;
}

export interface CreateQuizQuestion {
  number: number;
  question?: string | null;
  quizId: any;
}

export interface CreateSchool {
  address?: string | null;
  name: string;
}

export interface CreateStudent {
  birthDay?: any | null;
  classRoomId?: any | null;
  coursesToFollow?: any[] | null;
  email: string;
  firstName: string;
  lastName: string;
  schoolId?: any | null;
}

export interface CreateTeacher {
  birthDay?: any | null;
  classRooms?: any[] | null;
  email: string;
  firstName: string;
  lastName: string;
  schoolId?: any | null;
}

export interface CreateTopic {
  name: string;
}

export interface FilterClassRoom {
  id?: any | null;
  name?: string | null;
  nameLike?: string | null;
  schoolId?: any | null;
}

export interface FilterComment {
  id?: any | null;
}

export interface FilterConversation {
  id?: any | null;
}

export interface FilterCourse {
  id?: any | null;
  isCreator?: boolean | null;
  isStudent?: boolean | null;
  isTeacher?: boolean | null;
  name?: string | null;
  nameLike?: string | null;
  published?: boolean | null;
  topic?: string | null;
  url?: string | null;
}

export interface FilterLesson {
  id?: any | null;
  url?: string | null;
}

export interface FilterMenuVoice {
  id?: any | null;
  name?: string | null;
  parentId: any;
}

export interface FilterMessage {
  id?: any | null;
}

export interface FilterPayment {
  id?: any | null;
}

export interface FilterPercorso {
  id?: any | null;
}

export interface FilterQuiz {
  id?: any | null;
  lessonId?: any | null;
}

export interface FilterSchool {
  id?: any | null;
  name?: string | null;
  nameLike?: string | null;
}

export interface FilterShare {
  id?: any | null;
}

export interface FilterSlide {
  id?: any | null;
}

export interface FilterStreaming {
  id?: any | null;
}

export interface FilterTopic {
  id?: any | null;
}

export interface FilterUser {
  email?: string | null;
  emailLike?: string | null;
  firstName?: string | null;
  firstNameLike?: string | null;
  id?: any | null;
  lastName?: string | null;
  lastNameLike?: string | null;
  role?: RolesEnum | null;
}

export interface FilterVideo {
  id?: any | null;
}

export interface ForgotPassword {
  email?: string | null;
}

export interface JoinUserClassRoom {
  classRoomId: any;
  role: RolesEnum;
  userId: any;
}

export interface Login {
  password: string;
  userName: string;
}

export interface Paging {
  order?: OrderType | null;
  page?: number | null;
  size?: number | null;
  total?: boolean | null;
}

export interface PublishCourse {
  id: any;
  publish: boolean;
}

export interface QuizAnswer {
  answer?: string | null;
  questionId: any;
}

export interface RegisterSchool {
  emailReference: string;
  firstNameReference: string;
  lastNameReference: string;
  passwordConfirmReference: string;
  passwordReference: string;
  phoneNumber: string;
  schoolAddress: string;
  schoolName: string;
}

export interface RegisterStudent {
  birthDay: any;
  coursesOfInterest?: any[] | null;
  emailParent: string;
  firstNameChild: string;
  firstNameParent: string;
  lastNameChild: string;
  lastNameParent: string;
  notes?: string | null;
  phoneNumberParent: string;
  residenceParent: string;
  schoolAddress: string;
  schoolName: string;
}

export interface RegisterTeacher {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  skills: string;  
  curriculumId?: any;
  disponibility?: (TeacherDisponibilityItem | null)[] | null;
  notes?: string | null;


}

export interface ResetPassword {
  code?: string | null;
  confirmPassword?: string | null;
  password?: string | null;
  userId?: string | null;
}

export interface TeacherDisponibilityItem {
  description?: string | null;
  weekDay: WeekDayEnum;
}

export interface UnjoinUserClassRoom {
  classRoomId: any;
  userId: any;
}

export interface UpdateClassRoom {
  id: any;
  name: string;
}

export interface UpdateComment {
  content: string;
  id: any;
}

export interface UpdateCourse {
  classRoomId?: any | null;
  description?: string | null;
  id: any;
  name: string;
  price?: number | null;
  published?: boolean | null;
  schoolId?: any | null;
  skillLevel?: SkillEnum | null;
  subtitle?: string | null;
  teachers?: any[] | null;
  thumbnail?: string | null;
  topicId?: any | null;
}

export interface UpdateLesson {
  contentId?: any | null;
  courseId: any;
  id: any;
  name?: string | null;
}

export interface UpdateMenuVoice {
  id: any;
  model?: string | null;
  name: string;
  url?: string | null;
}

export interface UpdateMessage {
  content?: string | null;
  id: any;
}

export interface UpdatePercorso {
  courses?: any[] | null;
  description?: string | null;
  id: any;
  name: string;
  thumbnailId?: any | null;
  url?: string | null;
}

export interface UpdateQuizQuestion {
  answer?: string | null;
  choices?: string | null;
  explanation?: string | null;
  id: any;
  number: number;
  question?: string | null;
  quizId: any;
  type: QuestionType;
}

export interface UpdateSchool {
  address?: string | null;
  id: any;
  name?: string | null;
}

export interface UpdateStudent {
  birthDay?: any | null;
  classRoomId?: any | null;
  coursesToFollow?: any[] | null;
  email: string;
  firstName: string;
  id: any;
  lastName: string;
  schoolId?: any | null;
}

export interface UpdateTeacher {
  birthDay?: any | null;
  classRooms?: any[] | null;
  email: string;
  firstName: string;
  id: any;
  lastName: string;
  schoolId?: any | null;
}

export interface UpdateTopic {
  courses?: any[] | null;
  id: any;
  name?: string | null;
  thumbnailId: any;
  url?: string | null;
}

export interface UpdateUser {
  birthDay?: any | null;
  children?: any[] | null;
  email: string;
  firstName?: string | null;
  followedCourses?: any[] | null;
  heldCourses?: any[] | null;
  id?: any | null;
  lastName?: string | null;
  parents?: any[] | null;
  phoneNumber?: string | null;
  residence?: string | null;
  roles?: RolesEnum[] | null;
  schoolId?: any | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
