//import AccountModel, {initialAccount} from 'models/account/AccountModel';
import { thunk, action, Action, Thunk } from "easy-peasy";
//import { courses_courses } from "graphql/generated/types/Courses";

const initial = "";

export interface SearchStoreModel {
  text: string;
  loading: boolean;
  //result: courses_courses | undefined;
  start: Action<SearchStoreModel, string>;
  end: Action<SearchStoreModel/*, courses_courses*/>;
  cancel: Action<SearchStoreModel>;
  init: Action<SearchStoreModel>;
  
}

const search: SearchStoreModel = {
  text: initial,
  loading: false,
  start: action((state, payload) => {
    if(payload){
      state.text = payload;
      state.loading = true;
    } else {
      state.text = initial;
      //state.result = undefined;
      state.loading = false;
    }  
  }),
  end: action((state) => {
    //state.result = payload;
    state.loading = false;
  }),
  cancel: action(state => {
    state.text = initial;
    //state.result = undefined;
    state.loading = false;
  }),
  init: action(state => {
    state.text = initial;
    //state.result = undefined;
    state.loading = false;
  })
};

export default search;
