import gql from 'graphql-tag';
import { AccountFragment } from 'graphql/fragments/User';


export const MY_ACCOUNT = gql`
    query myAccount {
        myAccount {
            ...account
        }
    }
    ${AccountFragment}
`;

export const MY_PROFILE = gql`
    query myProfile {
        myAccount {
            birthDay
            ...account
        }
    }
    ${AccountFragment}
`;
