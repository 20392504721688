import gql from "graphql-tag";

export const AccountFragment = gql`
  fragment account on Account {
    id
    firstName
    lastName
    userName
    email
    roles
    school {
      id
      name
    }
  }
`;

export const StudentFragment = gql`
  fragment student on Student {
    id
    firstName
    lastName
    birthDay
    email
    userName
    classRoom {
      id
      name
    }
  }
`;


export const TeacherFragment = gql`
  fragment teacher on Teacher {
    id
    firstName
    lastName
    birthDay
    email
    userName
    classRooms {
      id
      name
    }
  }
`;

export const ReferenceFragment = gql`
  fragment reference on SchoolReference {
    id
    firstName
    lastName
    birthDay
    email
    userName
  }
`;
