import React, {ReactNode} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { Url } from "Constants";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Icon from "components/shared/icons/Icon";
import BodyText from "components/shared/typography/BodyText";
import LinkText from "components/shared/typography/LinkText";
import LinkRoute from "components/shared/typography/LinkRoute";


const TitleFooter = ({ children }: { children: ReactNode }) => (
  <BodyText
    box={{
      color:'#faf9f7'
    }}
    className="footerTitle"
    display="block"
    gutterBottom
  >
    {children}
  </BodyText>
);

const TextFooter = ({ children }: { children: ReactNode }) => (
  <BodyText display="block" box={{ color: "#faf9f7" }} gutterBottom>
    {children}
  </BodyText>
);

const SocialIcon = ({ icon, url }: { icon: string; url: string }) => (
  <BodyText box={{ fontSize: 22, display: "inline-block", color: "#999999" }}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Icon icon={icon} />
    </a>
  </BodyText>
);

const FooterContent = () => {

  return (
    <div style={{ margin: "auto", textAlign: "left" }}>
      <Grid container justify={"center"} spacing={10}>
        <Grid item xs={12} sm={4} md={4}>
          <TitleFooter>Mission</TitleFooter>
          <TextFooter>
            Fornire a bambini e ragazzi una esperienza formativa e didattica sui
            temi della programmazione preparandoli ad una eventuale
            frequentazione futura di istituti specializzati
          </TextFooter>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TitleFooter>Scopri Codecampus</TitleFooter>
          <TextFooter>
            Scopri tutta l'offerta formativa di Codecampus
          </TextFooter>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TitleFooter>Contattaci</TitleFooter>
          <TextFooter>
            Contattaci:
            <LinkText className="linkFooter" href="tel:+39 340 7245340">+39 340 7245340</LinkText>
            <br /> Scrivici:{" "}
            <LinkText className="linkFooter" href="mailto:info@codecampus.it">
              info@codecampus.it
            </LinkText>
          </TextFooter>
          <div>
            <TextFooter>Seguici anche su:</TextFooter>
            <SocialIcon
              url="https://www.facebook.com/CodeCampus2017/"
              icon="fab fa-facebook"
            />
            <SocialIcon
              url="https://www.instagram.com/codecampus2017/"
              icon="fab fa-instagram"
            />
            <SocialIcon
              url="https://telegram.me/CodeCampus2017"
              icon="fab fa-telegram-plane"
            />
            <SocialIcon
              url="https://www.linkedin.com/company/codecampus2017/"
              icon="fab fa-linkedin"
            />
          </div>
        </Grid>
        <Grid style={{ textAlign: "center" }} item xs={12}>
          <Divider style={{ margin: "24px auto", width: 60 }} />
          <BodyText display="block" align="center"  box={{  color: "#faf9f7" }}>
            © 2016 CodeCampus | P.Iva 02820820963 | Tutti i diritti riservati
          </BodyText>
          <div>
            <LinkRoute className="linkFooter" target="_blank" to={Url.privacy}>
              Privacy Policy
            </LinkRoute>{" "}
            |{" "}
            <LinkRoute className="linkFooter" target="_blank" to={Url.cookie}>
              Cookie Policy
            </LinkRoute>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default FooterContent;
