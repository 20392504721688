import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "App";
import * as serviceWorker from "serviceWorker";
import "./locales/i18n";

const rootElement = document.getElementById("root");

if (rootElement && rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept("./App", () => {
      const NextApp = require("./App").default;
      ReactDOM.render(<NextApp />, rootElement);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
