import React from "react";
import TextField, {
  CustomTextFieldProps
} from "components/shared/fields/TextField";
import { useField } from "react-final-form";

export type FormTextFieldProps<T> = {
  name: Extract<keyof T, string>;
  label: string;
} & CustomTextFieldProps;

function Component<T>(props: FormTextFieldProps<T>) {
  const {input, meta} = useField(props.name);
  // useWhyDidYouUpdate("FormTextField field:" + props.name, input);
  // useWhyDidYouUpdate("FormTextField meta:" + props.name, meta);
  // useWhyDidYouUpdate("FormTextField props:" + props.name, props);
  return <TextField helperText={meta.error} {...input} {...props} />;
}

export const FormTextField = React.memo(Component) as unknown as typeof Component;
