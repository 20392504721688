import React, { lazy } from "react";
import { Switch, Redirect } from "react-router";
import { Url } from "Constants";
import PublicRoute from "components/routes/public-route";
import PrivateRoute from "components/routes/private-route";
import useSchoolEditPage from "components/pages/school/edit/useSchoolEditPage";
// import Contacts from "components/pages/contacts/Contacts";

const TestPage = lazy(() => import("components/pages/test/TestPage"));

const HomePage = lazy(() => import("components/pages/home/HomePage"));
const LessonPage = lazy(() =>
  import("components/pages/lesson/view/LessonPage")
);
const CoursePage = lazy(() =>
  import("components/pages/course/view/CoursePage")
);
const TopicPage = lazy(() => import("components/pages/topic/TopicPage"));
const Page404 = lazy(() => import("components/pages/errors/404"));
const LoginPage = lazy(() => import("components/pages/login/LoginModal"));
const CourseCreatePage = lazy(() =>
  import("components/pages/course/edit/CourseEditPage")
);
const PrivacyPage = lazy(() => import("components/pages/privacy/privacy"));
const CookiesPage = lazy(() => import("components/pages/privacy/cookies"));
const RegisterStudentPage = lazy(() =>
  import("components/pages/register/students/RegisterStudentPage")
);
const RegisterTeacherPage = lazy(() =>
  import("components/pages/register/teachers/RegisterTeacherPage")
);
const RegisterSchoolPage = lazy(() =>
  import("components/pages/register/schools/RegisterSchoolPage")
);
const AdminPage = lazy(() => import("components/pages/admin/AdminPage"));

const SearchPage = lazy(() => import("components/pages/search/SearchPage"));

const ProfilePage = lazy(() =>
  import("components/pages/user/profile/ProfilePage")
);
const CreateStudentPage = lazy(() =>
  import("components/pages/user/create/CreateStudentPage")
);
const CreateTeacherPage = lazy(() =>
  import("components/pages/user/create/CreateTeacherPage")
);
const CourseTable = lazy(() =>
  import("components/pages/course/table/CoursesTable")
);
const CoursesITeach = lazy(() =>
  import("components/pages/course/table/CoursesITeach")
);
// const UserTable = lazy(() => import("components/pages/user/table/UserTable"));
// const UserEditTable = lazy(() => import("components/pages/user/edit/UserEditTable"));
const StudentTable = lazy(() =>
  import("components/pages/user/table/StudentTable")
);
const TeacherTable = lazy(() =>
  import("components/pages/user/table/TeacherTable")
);
const SchoolTable = lazy(() =>
  import("components/pages/school/table/SchoolTable")
);
const SchoolEditPage = lazy(() =>
  import("components/pages/school/edit/SchoolEditPage")
);
const ClassRoomTable = lazy(() =>
  import("components/pages/classroom/table/ClassRoomTable")
);
const ClassRoomEditPage = lazy(() =>
  import("components/pages/classroom/edit/ClassRoomEditPage")
);
// const UserEditPage = lazy(() =>
//   import("components/pages/user/edit/UserEditPage")
// );
const StudentEditPage = lazy(() =>
  import("components/pages/user/edit/StudentEditPage")
);
const TeacherEditPage = lazy(() =>
  import("components/pages/user/edit/TeacherEditPage")
);
const ChatPage = lazy(() =>
  import("components/pages/chat/ChatPage")
);
const Projects = lazy(() =>
  import("components/pages/projects/Projects")
);
const Contacts = lazy(() =>
  import("components/pages/contacts/Contacts")
);

const Routes = () => {
  return (
    <Switch>
      <PublicRoute path={Url.Course.search} component={SearchPage} />
      <PublicRoute path={Url.cookie} component={CookiesPage} />
      <PublicRoute path={Url.privacy} component={PrivacyPage} />
      <PublicRoute
        path={Url.Iscrizioni.insegnanti}
        component={RegisterTeacherPage}
      />
      <PublicRoute
        path={Url.Iscrizioni.scuole}
        component={RegisterSchoolPage}
      />
      <PublicRoute
        path={Url.Iscrizioni.studenti}
        component={RegisterStudentPage}
      />
      <PublicRoute path={Url.home} exact={true} component={HomePage} />
      {/* TODO: RIMUOVERE */}
      <PublicRoute path={Url.Test.test} component={TestPage} />

      <PrivateRoute
        exact={true}
        path={`${Url.School.view.join(":")}${Url.Student.table}`}
        component={StudentTable}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.view.join(":")}${Url.Teacher.table}`}
        component={TeacherTable}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.edit.join(":")}${Url.Student.edit.join(":")}`}
        component={StudentEditPage}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.edit.join(":")}${Url.Teacher.edit.join(":")}`}
        component={TeacherEditPage}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.Course.Manage.all}`}
        component={CourseTable}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.Course.Manage.iteach}`}
        component={CoursesITeach}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.table}`}
        component={SchoolTable}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.edit.join(":")}`}
        component={SchoolEditPage}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.view.join(":")}${Url.ClassRoom.table}`}
        component={ClassRoomTable}
      />
      <PrivateRoute
        exact={true}
        path={`${Url.School.edit.join(":")}${Url.ClassRoom.edit.join(":")}`}
        component={ClassRoomEditPage}
      />
      <PublicRoute
        path={`${Url.Topic.view.join(":")}`}
        component={TopicPage} />
      <PrivateRoute
        layout={{ rightSidebar: true }}
        path={`${Url.Course.view.join(":")}${Url.Lesson.view.join(":")}`}
        component={LessonPage}
      />
      <PublicRoute
        path={`${Url.Course.view.join(":")}`}
        component={CoursePage}
      />
      <PrivateRoute
        layout={{ leftSidebar: true }}
        path={`${Url.Course.Edit.edit.join(":")}`}
        component={CourseCreatePage}
      />
      <PublicRoute path={Url.login} component={LoginPage} />
      <PrivateRoute component={ProfilePage} path={Url.User.profile} />
      <PrivateRoute
        component={CreateStudentPage}
        exact={true}
        path={`${Url.School.view.join(":")}${Url.Student.create}`}
      />
      <PrivateRoute
        component={CreateTeacherPage}
        exact={true}
        path={`${Url.School.view.join(":")}${Url.Teacher.create}`}
      />
      <PrivateRoute
        layout={{ leftSidebar: true, footer: false, fixedHeader: true }}
        component={ChatPage}
        exact={true}
        path={`${Url.Chat.view}`}
      />
      <PublicRoute
        path={Url.Projects}
        component={Projects}
      />
      <PublicRoute
        path={Url.Contacts}
        component={Contacts}
      />
      <PublicRoute component={Page404} />
    </Switch>
  );
};

export default Routes;
