import React from "react";
import Button, { ButtonDefaultProps } from "./Button";

type Props = { } & ButtonDefaultProps

export default function SubmitButton(props: Props) {
  return (
    <Button
      size="big"
      type="submit"
      color="primary"
      variant="contained"
      {...props}
    />
  );
}
