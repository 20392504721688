import React, { useState, SyntheticEvent } from "react";
import IconButton, { IconButtonTypeMap } from "@material-ui/core/IconButton";
import { useStore, useActions } from "store";
import {
  Menu,
  MenuItem,
  Avatar,
  Popper,
  MenuList,
  Paper
} from "@material-ui/core";
import {
  usePopupState,
  bindMenu,
  bindPopper,
  bindHover
} from "material-ui-popup-state/hooks";
import { useMutation } from "@apollo/react-hooks";
import { LOGOUT } from "graphql/mutations/mutationUser";
import { MenuProps } from "@material-ui/core/Menu";
import log from "utils/log";
import { RolesEnum } from "graphql/generated/types/globalTypes";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Url } from "Constants";
import styled from "styled-components";
import { MenuItemType } from "components/shared/cascading-menu/cascading-menu";


const AccountMenu = () => {
  const logoutStore = useActions(actions => actions.auth.logout);
  const history = useHistory();
  const user = useStore(store => store.auth.account);

  const [logout, { loading, client }] = useMutation(LOGOUT, {
    onCompleted: () => {
      client?.clearStore();
      logoutStore();
    },
    // onError: (...messages) => {
    //   log.e(...messages);
    // }
  });

  const account = useStore(state => state.auth.account);


  const popupState = usePopupState({
    variant: "popover",
    popupId: "profileMenu"
  });



  const handleLogout = async () => {
    logout();
  };

  const handleProfile = () => {
    popupState.close();
    history.push(Url.User.profile);
  };

  const handleChat = () => {
    popupState.close();
    history.push(Url.Chat.view);
  };

  const links: MenuItemType[] = [
    {
      id: "profilo",
      name: "Profilo",
      onClick: handleProfile
    },
    {
      id: "chat",
      name: "Chat",
      onClick: handleChat,
      roles: [RolesEnum.SCHOOL_REFERENCE, RolesEnum.ADMIN, RolesEnum.TEACHER]
    },
    {
      id: "logout",
      name: "Logout",
      onClick: handleLogout
    }
  ];

  const AvatarContent = () => (
    <Avatar>
      {account!.userName!.length > 1 ? account!.userName!.substring(0, 2) : ""}
    </Avatar>
  );

  // const ProfileMenu = (props: MenuProps) => (
  //   <Menu
  //     getContentAnchorEl={null}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  //     transformOrigin={{ vertical: "top", horizontal: "left" }}
  //     {...props}
  //   />
  // );

  const Links = () => (
    <>
      {links.map(
        l =>
          (!l.roles || _.some(user.roles!, el => _.includes(l.roles, el))) && (
            <MenuItem key={l.id} onClick={l.onClick}>
              {l.name}
            </MenuItem>
          )
      )}
    </>
  );

  return (
    <div>
      <IconButton
        {...bindHover(popupState)}
        aria-haspopup="true"
        color="inherit"
      >
        <AvatarContent />
      </IconButton>
      <StyledPopper anchorEl={null} {...bindMenu(popupState)}>
        <Paper>
          <MenuList>
            <Links />
          </MenuList>
        </Paper>
      </StyledPopper>
    </div>
  );
};

const StyledPopper = styled(Popper)`
  z-index: ${p => p.theme.zIndex.drawer + 2};
`;

export default AccountMenu;
