    
import auth, { AuthStoreModel } from "./auth";
import {
    loginModal, 
    LoginModalModel, 
    createCourseModal,
    CreateCourseModalModel,
    createSchoolModal,
    CreateSchoolModalModel,
} from "./modals";
import layout, { LayoutModel } from "./layout";
import search, {SearchStoreModel} from "./search";

export interface ApplicationModel {
    auth: AuthStoreModel,
    loginModal: LoginModalModel,
    layout: LayoutModel,
    search: SearchStoreModel,
    createCourseModal: CreateCourseModalModel,
    createSchoolModal: CreateSchoolModalModel,
};

const model: ApplicationModel = {
    auth,
    loginModal,
    layout,
    search,
    createCourseModal,
    createSchoolModal,
};

export default model;