import React from "react";
import MuiTypography, {
  TypographyProps as MuiTypographyProps
} from "@material-ui/core/Typography";
import { DefaultBox, DefaultBoxProps } from "../DefaultBox";
import styled from "styled-components";
import { DefaultFontsType } from "themes/theme-default";

export type StyledTypographyProps = {
  color?: string;
  fontWeight?: number | string;
  display?: string;
  fontFamily?: DefaultFontsType;
};

export type TypographyProps = {
  component?: any
  box?: Pick<
    DefaultBoxProps,
    Exclude<keyof DefaultBoxProps, keyof StyledTypographyProps>
  > &
    StyledTypographyProps;
} & MuiTypographyProps;

const StyledTypography = styled(
  ({ color, display, fontWeight, fontFamily, ...props }) => (
    <MuiTypography {...props} />
  )
)<StyledTypographyProps>`
  color: ${p => p.color};
  display: ${p => p.display};
  font-weight: ${p => p.fontWeight};
  font-family: ${p => p.fontFamily};
`;

export function Typography({ box, ...props }: TypographyProps) {

  return (
    <StyledTypography
      {...props}
      fontWeight={box?.fontWeight}
      display={box?.display}
      color={box?.color}
      fontFamily={box?.fontFamily}
    />
  );
}
