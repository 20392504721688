import React from "react";
import styled from "styled-components";
import Container from "@material-ui/core/Container";

const StyledForm = styled.form`
  padding: 38px 18px 56px 18px;
`;

const FormLayout = (props: React.FormHTMLAttributes<HTMLFormElement>) => {
  return (
    <Container maxWidth="lg">
      <StyledForm {...props}>{props.children}</StyledForm>
    </Container>
  );
};

export default FormLayout;
