import React from "react";
import TextField, {
  CustomTextFieldProps
} from "components/shared/fields/TextField";
import { useFormContext } from "react-hook-form";
import useWhyDidYouUpdate from "hooks/use-why-did-you-update";
import { ValidationError } from "yup";

export type FormTextFieldProps<T> = {
  name: Extract<keyof T, string>;
  label: string;
} & CustomTextFieldProps;

function Component<T>(props: FormTextFieldProps<T>) {
  const form = useFormContext();
  //useWhyDidYouUpdate("FormTextField form:" + props.name, form);

  const error = (form.errors[props.name] as unknown as ValidationError);

  return <TextField error={error} helperText={error ? error.message : undefined} {...props} inputRef={form.register} />;
}

export const FormTextField = (React.memo(
  Component
) as unknown) as typeof Component;
