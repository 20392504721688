//import AccountModel, {initialAccount} from 'models/account/AccountModel';
import { thunk, action, Action, Thunk } from "easy-peasy";
import { myAccount, myAccount_myAccount } from "graphql/generated/types/MyAccount";
import log from "utils/log";

const initialAccount: myAccount_myAccount = {
  id: "",
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  school: null,
  roles: [],
  __typename: "Account"
};

export interface AuthStoreModel {
  account: myAccount_myAccount;
  isAuthenticated: boolean;
  loginSucceed: Action<AuthStoreModel, myAccount_myAccount>;
  logout: Action<AuthStoreModel>;
  updateAccount: Action<AuthStoreModel, myAccount_myAccount>;
}

const auth: AuthStoreModel = {
  account: initialAccount,
  isAuthenticated: false,
  loginSucceed: action((state, payload) => {
    state.account = payload;
    state.isAuthenticated = true;
  }),
  logout: action(state => {
    state.account = initialAccount;
    state.isAuthenticated = false;
  }),
  updateAccount: action((state, payload) => {
    state.account = payload
  })
};

export default auth;
