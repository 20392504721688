import React, { ReactNode } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { makeStyles } from "@material-ui/core";
import XButton from "components/shared/buttons/XButton";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    padding: theme.spacing(0, 3, 3, 3)
  }
}));

type Props = {
  title?: string;
  open: boolean;
  onClose?: Function;
  cancellable?: boolean;
  children: ReactNode;
  fullScreen?: boolean;
};

const AppDialog = ({
  title = "",
  open,
  onClose,
  cancellable = true,
  children,
  ...other
}: Props) => {
  const classes = useStyles();

  return (
    <Dialog
      TransitionComponent={Fade}
      open={open}
      scroll="body"
      onBackdropClick={() => onClose && onClose()}
      disableEscapeKeyDown
      aria-labelledby="confirmation-dialog-title"
      {...other}
    >
      <DialogTitle disableTypography>
        {title ? <h2>{title}</h2> : null}
        <XButton onClick={() => onClose && onClose()} />
      </DialogTitle>
      <DialogContent className={classes.content}>{children}</DialogContent>
    </Dialog>
  );
};

export default AppDialog;
