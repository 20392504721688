import {
  createStore,
  createTypedHooks
} from "easy-peasy";
import model, { ApplicationModel } from "./models";
import logger from 'redux-logger'
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import { batchedSubscribe } from 'redux-batched-subscribe';


const {
  useStoreActions: useActions,
  useStoreState: useStore,
  useStoreDispatch: useDispatch
} = createTypedHooks<ApplicationModel>();

export { useActions, useDispatch, useStore };

const persistConfig: PersistConfig = {
  key: "appstate",
  storage,
  whitelist: ["auth"]
};

const store = createStore(model, {
  name: "GlobalStore",
  reducerEnhancer: reducer => persistReducer(persistConfig, reducer),
  middleware: [logger]
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./models", () => {
    store.reconfigure(model);
  });
}

export default store;
