const fnNameMatcher = /([^(]+)@|at ([^(]+) \(/;

function fnName(str: string) {
  const regexResult = fnNameMatcher.exec(str);
  if (regexResult) return regexResult[1] || regexResult[2];
}

const logWithCallerName = (...messages: any) => {
  const logLines = new Error()!.stack!.split("\n");
  //console.log("loglines",logLines[1])
  const callerName = fnName(logLines[1]);

  if (callerName !== null) {
    if (callerName !== "log") {
      console.log(callerName, ...messages);
    } else {
      console.log(fnName(logLines[2]), ...messages);
    }
  } else {
    console.log(...messages);
  }
};

const infoOnlyMessages = (...messages: any) => console.info(...messages);
const errorOnlyMessages = (...messages: any) => console.error(...messages);
const debugOnlyMessages = (...messages: any) => console.debug(...messages);

const logIfEnabled = (callback: Function, ...messages: any) =>  {
  if(log.enabled)
    callback(...messages);
}

const log = {
  i: (...messages: any) => logIfEnabled(infoOnlyMessages, ...messages),
  d: (...messages: any) => {
    logIfEnabled(debugOnlyMessages, ...messages)
  },
  e: (...messages: any) => logIfEnabled(errorOnlyMessages, ...messages),
  enabled: true
};

export default log;
