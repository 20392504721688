import createMuiTheme, {
  ThemeOptions
} from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { Overrides } from "@material-ui/core/styles/overrides";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { borderColor } from "@material-ui/system";

// export const defaultFonts = [
//   '"Patua One"',
//   "Arial",
//   "Tahoma",
//   "sans-serif"
// ].join(",");

export const themeFonts = ["Roboto", "Helvetica", "Arial", "sans-serif", '"Patua One"'] as const;

export const defaultFonts = themeFonts.join(",");

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never;

export type DefaultFontsType = ElementType<typeof themeFonts>;

// crete object of overrides
const overrides: Overrides = {
  MuiGrid: {
    container: {
      margin: "0 !important",
      width: "100% !important"
    }
  },
  MuiLink: {
    underlineHover: {
      "&:hover": {
        textDecoration: "none"
      }
    }
  },
  MuiOutlinedInput: {
    notchedOutline: {
      borderWidth: "1px !important",
      borderColor: "rgba(76, 63, 166, 1) !important"
    },
    root: {
      "&:focused": {
        borderWidth: "1px !important",
        borderColor: "rgba(76, 63, 166, 1) !important"
      }
    }
  }
};

const palette: PaletteOptions = {
  common: {
    black: "#000",
    white: "#fff"
  },
  background: {
    paper: "#ffffff", //"rgba(245, 245, 245, 1)",
    default: "#f8f8fa" //"#fafafa"
  },
  primary: {
    light: "rgba(76, 63, 166, 1)",
    main: "#18a864", //"rgba(16, 23, 118, 1)",
    dark: "#29303b",
    contrastText: "#fff"
  },
  secondary: {
    light: "#18a864",
    main: "rgba(154, 66, 171, 1)",
    dark: "#7200db",
    contrastText: "#fff"
  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#fff"
  },
  text: {
    primary: "rgba(0, 0, 0, 0.87)",
    secondary: "#686f7a",
    disabled: "rgba(0, 0, 0, 0.38)",
    hint: "rgba(0, 0, 0, 0.38)"
  }
};

// get mui theme
const muiTheme = createMuiTheme({
  palette: {
    ...palette
  },
  typography: {
    fontFamily: defaultFonts,
    fontSize: 14,
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700 // Roboto Condensed
  },
  shape: {
    borderRadius: 6
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true // No more ripple, on the whole application 💣!
    },
    MuiMenu: {
      transitionDuration: 0
    }
  },
  // transitions: {
  //   // So we have `transition: none;` everywhere
  //   create: () => 'none',
  // },
  overrides: {
    ...overrides
  }
});

// add overrides to the app theme
const theme = responsiveFontSizes(muiTheme);

export default theme;
