import React from "react";
import logo from "static/images/site/logo.png";
import LinkWrap from "components/shared/link-wrap";
import styled from "styled-components";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useStore, useActions } from "store";
import { Url } from "Constants";
import AccountMenu from "components/menus/AccountMenu";
import ActionSecondaryButton from "components/shared/buttons/ActionSecondaryButton";

const useStyles = makeStyles(
  ({ spacing, transitions, breakpoints, palette, shape }: Theme) => ({
    header: {
      fontWeight: 900,
      minWidth: 0,
      fontSize: 18
    },
    grow: {
      flexGrow: 1
    },
    search: {
      position: "relative",
      marginRight: 8,
      borderRadius: shape.borderRadius,
      background: palette.grey[200],
      "&:hover": {
        background: palette.grey[300]
      },
      marginLeft: 0,
      width: "100%",
      [breakpoints.up("sm")]: {
        marginLeft: spacing(1),
        width: "auto"
      }
    },
    searchIcon: {
      width: spacing(9),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    logo: {
      mariginEnd: spacing(1)
    },
    menuButton: {
      marginLeft: "30px"
    }
  })
);

const StyledActionButton = styled(ActionSecondaryButton)`
 /* margin-left: 8px;
  margin-right: 8px;*/
  margin-left: auto;
`;

const MenuBar = () => {
 const classes = useStyles();
 const isAuthenticated = useStore(state => state.auth.isAuthenticated);

   const loginModal = useActions(actions => actions.loginModal);
 const LoginOrProfileButton = () =>
 isAuthenticated ? (
   <AccountMenu />
 ) : (
   <StyledActionButton onClick={() => loginModal.open()} compact={true}>
     Login
   </StyledActionButton>
 );

return(
  <header id="menuBar">
      <LinkWrap className={classes.logo} to={Url.home}>
        <img
          alt="Codecampus"
          style={{ marginRight: 32 }}
          width="auto"
          height="53px"
          data-purpose="codecampus-brand-logo"
          src={logo}
        />
      </LinkWrap>
      <span className="menuPayoff">La prima piattaforma italiana di coding per bambini e ragazzi</span>
      <LoginOrProfileButton />
     
  </header>
);
}
export default MenuBar;

  