import React from 'react';
import CookieConsent from "react-cookie-consent";

    const Cookiebanner = () => {
        return(
            <CookieConsent 
            buttonText="ACCONSENTO"
            style={{ background: "rgba(255,255,255,0.8)", color: '#111' }}
            buttonStyle={{ color: "#fff", background: "#18a864", fontSize: "13px", borderRadius: '8px', padding: '10px 25px' }}
            >
           Questo sito fa uso di cookie per migliorare l’esperienza di navigazione degli utenti e per raccogliere informazioni sull’utilizzo del sito stesso. Utilizziamo sia cookie tecnici sia cookie di parti terze per inviare messaggi promozionali sulla base dei comportamenti degli utenti. Può conoscere i dettagli consultando la nostra privacy policy qui. Cliccando sul bottone "Acconsento" o proseguendo nella navigazione si accetta l’uso dei cookie; in caso contrario è possibile abbandonare il sito.
          </CookieConsent>
        )
    }
    export default Cookiebanner;
