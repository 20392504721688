import React from "react";
import {Typography, TypographyProps} from './Typography';
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import styled from "styled-components";

const TypographyStyled = styled(Typography)`
  color: ${props => props.theme.palette.secondary.light};
`

export default function LinkRoute(props: TypographyProps & RouterLinkProps) {

  
  return (
    <TypographyStyled
      {...props}
      component={RouterLink as any}
    />
  );
}
