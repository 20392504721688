import {
  FormContext,
  useForm,
  FormProps,
  UseFormOptions
} from "react-hook-form";
import React, { Ref, forwardRef, useImperativeHandle, useState } from "react";
import { DevTool } from 'react-hook-form-devtools';

export type FormHookMethods<T> = Omit<FormProps<T>, "children">& SubmitProps;

export type FormRef<T> = React.MutableRefObject<FormHookMethods<T> | undefined>;

type SubmitProps = {
    isSubmitting: boolean;
    setSubmitting: (toggle: boolean) => void;
}

type Props<T> = {
  children:
    | JSX.Element[]
    | JSX.Element
    | ((bag: FormHookMethods<T> ) => JSX.Element[] | JSX.Element);
  defaultValues: T;
} & Omit<UseFormOptions<T>, "defaultValues">;

function Component<T extends any>(
  { children, ...rest }: Props<T>,
  ref?: Ref<FormHookMethods<T> | undefined>
) {
  const form = useForm<T>(rest);
  const [isSubmitting, setSubmitting] = useState(false);
  const methods: FormHookMethods<T> = { isSubmitting, setSubmitting, ...form };
  useImperativeHandle(ref, () => methods);
/*  console.log('Component');
  console.log(Component);
  console.log('FORM');
  console.log(form);
  console.log('ref');
  console.log(ref);*/

  return (
    <FormContext {...form}>
      {typeof children === "function"
        ? children(methods)
        : children}
        {(window as any).debugform && <DevTool control={form.control} />}
    </FormContext>
  );
}

export const FormHookContext = forwardRef(Component);
