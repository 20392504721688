import React, { useState } from "react"; 
// @ts-ignore
import { slide as Menu } from "react-burger-menu";
import LinkWrap from "components/shared/link-wrap";
import { Url } from "Constants";
import logo from "static/images/site/logo.png";

import CascadingHoverMenu, {
  MenuItemType
} from "components/shared/cascading-menu/cascading-menu";
import Item from "components/shared/cascading-menu/item";
import { useHistory } from "react-router-dom"; 
import urljoin from "url-join";
import { useStore, useActions } from "store";
import _ from "lodash";
import { RolesEnum } from "graphql/generated/types/globalTypes";

interface Props{
  pageWrapId: string;
  outerContainerId: string;
  right?: boolean;
}

export const Sidebar: React.FC<Props> = ({pageWrapId,outerContainerId,right}) => {
  const [menuOpenState, setMenuOpenState] = useState(false);
  const [menuSecondState, setMenuSecondState] = useState('');
  const stateChangeHandler = (newState:any) => {
       console.log(newState.isOpen);
       setMenuOpenState(newState.isOpen);
       if(!newState.isOpen)
       setMenuSecondState('');
  }

  const menuSecondHandler = (el: string) => {
    console.log('el:'+el);
    if(menuSecondState != el)
      setMenuSecondState(el);
    else
     setMenuSecondState('');
  }

  const closeMenuHandler = () => {
    setMenuOpenState(false);
    setMenuSecondState('');
  }



  const user = useStore(store => store.auth.account);

  const history = useHistory();

  const isAuthenticated = useStore(state => state.auth.isAuthenticated);
  const account = useStore(state => state.auth.account);
  const createCourseModalOpen = useActions(
    actions => actions.createCourseModal.open
  );

  const menu: MenuItemType[] = [
    {
      id: "gest1",
      name: "Gestione",
      subItems: [
        {
          id: "modificascuola",
          name: "Scuola",
          onClick: () => {history.push(urljoin(Url.School.edit[0], account.school?.id));
            closeMenuHandler();
        },
          roles: [RolesEnum.SCHOOL_REFERENCE]
        },
        {
          id: "creacorso",
          name: "Crea Corso",
          onClick: () => {createCourseModalOpen();
            closeMenuHandler();
        },
          roles: [RolesEnum.ADMIN, RolesEnum.SCHOOL_REFERENCE, RolesEnum.TEACHER]
        },
        {
          id: "imieicorsi",
          name: "I miei corsi",
          onClick: () => {history.push(Url.Course.Manage.iteach);
            closeMenuHandler();
        },
          roles: [
            RolesEnum.ADMIN,
            RolesEnum.TEACHER,
            RolesEnum.SCHOOL_REFERENCE
          ]
        },
        {
          id: "tuttiicorsi",
          name: "Tutti i corsi",
          onClick: () => {history.push(Url.Course.Manage.all);
            closeMenuHandler();
        },
          roles: [
            RolesEnum.ADMIN,
          ]
        },
        {
          id: "scuole",
          name: "Scuole",
          onClick: () => { history.push(Url.School.table);
            closeMenuHandler();
          },
          roles: [
            RolesEnum.ADMIN
          ]
        }
      ]
    },
    {
      id: "opt1",
      name: "Iscriviti a CodeCampus",
      subItems: [
  /*      {
          id: "opt1.1",
          name: "Iscrizione Studenti",
          onClick: () => {history.push(Url.Iscrizioni.studenti);
            setMenuOpenState(false);
          },
        },
        {
          id: "opt1.2",
          name: "Iscrizione Insegnanti",
          onClick: () => {history.push(Url.Iscrizioni.insegnanti);
            setMenuOpenState(false);
          },
        },*/
        {
          id: "opt1.3",
          name: "Iscrizione Scuole",
          onClick: () => {history.push(Url.Iscrizioni.scuole);
            closeMenuHandler();
          },
        }
      ]
    },
    {
      id: "opt2",
      name: "I Corsi",
      subItems: [
        {
          id: "opt2.1",
          name: "SCRATCH",
          onClick: () => ( history.push(Url.Topic.view[0]+"scratch") , closeMenuHandler())
        },
        {
          id: "opt2.2",
          name: "APP INVENTOR",
          onClick: () => ( history.push(Url.Topic.view[0]+ "minecraft"), closeMenuHandler())
        },
        {
          id: "opt2.3",
          name: "MINECRAFT",
          onClick: () => ( history.push(Url.Topic.view[0] + "minecraft"), closeMenuHandler())
        },
        {
          id: "opt2.4",
          name: "PROGRAMMAZIONE UNPLUGGED",
          onClick: () => ( history.push(Url.Topic.view[0] + "unplugged"), closeMenuHandler())
        },
        {
          id: "opt2.5",
          name: "ROBOTICA EDUCATIVA",
          onClick: () => ( history.push(Url.Topic.view[0] + "unplugged"), closeMenuHandler())
        },
        {
          id: "opt2.6",
          name: "DIGITAL STORYTELLING",
          onClick: () => ( history.push(Url.Topic.view[0] + "unplugged"), closeMenuHandler())
        },
        {
          id: "opt2.7",
          name: "STORYBOARD",
          onClick: () => ( history.push(Url.Topic.view[0] + "unplugged"), closeMenuHandler())
        }
      ]
    },
    {
      id: "opt3",
      name: "Progetti Attivi",
      onClick: () => { history.push(Url.Projects);
        closeMenuHandler();
      }
    },
    {
      id: "opt4",
      name: "Contatti",
      onClick: () => { history.push(Url.Contacts);
        closeMenuHandler();
      }
      
    },
  ];

const hasRoles = (m: MenuItemType) =>
  !m.roles || _.intersection(user.roles!, m.roles).length > 0;

const filterMenuWithRoles = (menu: MenuItemType[]) =>
  menu
    .filter(m => hasRoles(m))
    .map(m2 => {
      if (!m2.subItems) return m2;
      m2.subItems = filterMenuWithRoles(m2.subItems);
      return m2;
    });

  const options = filterMenuWithRoles(menu);


  let classLink = '';

  return (
    // Pass on our props
    <Menu  className="bm-collapse-content"  {...pageWrapId} {...outerContainerId} {...right}   isOpen={menuOpenState}  onStateChange={(state:any) => stateChangeHandler(state) }  >
      <LinkWrap  to={Url.home}>
        <img
          alt="Codecampus"
          style={{ marginRight: 26 }}
          width="auto"
          height="53px" 
          data-purpose="codecampus-brand-logo"
          src={logo}
        /> 
      </LinkWrap>    

      {options.map(s => (
        <div className={ (s.subItems) ? ( "bm-subitems bm-collapse") : ("bm-collapse") } id={s.id}  >    
          { (s.subItems) ?
              <a className={ (s.id == menuSecondState) ? ( "menu-item active") : ("menu-item") } onClick={() => menuSecondHandler(s.id)} title={"#"+s.id} key={s.id} > {s.name}  </a>
          : (<a className="menu-item" onClick={(e: any) => {  s.onClick && s.onClick();  }} key={s.id} > {s.name}  </a>) } 
          <div className="bm-content">
             <ul>
                {(s.subItems)
                ? (s.subItems.map(sub => ( <li className="menu-item"
                  key={sub.id}
                  onClick={(e: any) => {  sub.onClick && sub.onClick();   }}
                  title={sub.name}
                >  {sub.name}</li> )) )
                : (null) }             
              </ul>
           </div>
         </div>))}
    </Menu>
  );
};

export default Sidebar;

  