import React from "react";
import Button, { ButtonDefaultProps } from "./Button";
import { Theme, makeStyles } from "@material-ui/core";
import styled from "styled-components";


const StyledXButton = styled(Button)`
  position: absolute;
  padding: ${p => p.theme.spacing(1)}px;
  right: ${p => p.theme.spacing(1)}px;
  top: ${p => p.theme.spacing(1)}px;
  &:hover {
    background-color: transparent;
  }
`;

export default function XButton(props: ButtonDefaultProps) {
  return (
    <StyledXButton icon="fas fa-times" {...props} />
  );
}

export function DeleteXButton(props: ButtonDefaultProps) {
  return (
    <Button icon="fas fa-times" {...props} />
  );
}
