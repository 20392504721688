import { thunk, action, Action, Thunk } from "easy-peasy";

export interface LayoutProps {
  rightSidebar?: boolean;
  leftSidebar?: boolean;
  footer?: boolean;
  fixedHeader?: boolean;
}

export interface LayoutModel {
  layout: LayoutProps;
  change: Action<LayoutModel, LayoutProps>;
}

const layout: LayoutModel = {
  layout: {
    rightSidebar: false,
    leftSidebar: false,
    footer: true,
    fixedHeader: false,
  },
  change: action((state, payload) => {
    state.layout = payload;
  })
};

export default layout;
