import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import PageTransition from "components/shared/transitions/fade";
import { useStore, useActions } from "store";
import { LayoutProps } from "store/models/layout";
import { Url } from "Constants";

type Props = {
  component: React.FC<any>;
  layout?: LayoutProps;
} & RouteProps;

const defaultLayout: LayoutProps = {
  rightSidebar: false,
  leftSidebar: false,
  footer: true,
  fixedHeader: false,
};

function PrivateRoute({ component: Component, layout = {}, ...rest }: Props) {
  const isAuthenticated = useStore(state => state.auth.isAuthenticated);
  const changeLayout = useActions(actions => actions.layout.change);

  changeLayout({ ...defaultLayout, ...layout });

  if (!Component)
    throw new Error(`A component needs to be specified for path ${rest.path}`);

  return (
    <Route
      {...rest}
      render={renderProps =>
        isAuthenticated ? (
          <PageTransition>
            <Component {...renderProps} />
          </PageTransition>
        ) : (
          <Redirect
            to={{
              pathname: Url.home,
              state: { from: renderProps.location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
