import React from "react";
import { Route, RouteProps } from "react-router-dom";
import PageTransition from "components/shared/transitions/fade";
import { useActions } from "store";
import { LayoutProps } from "store/models/layout";

type Props = {
  component: React.FC<any>;
  layout?: LayoutProps;
} & RouteProps;

const defaultLayout: LayoutProps = {
  rightSidebar: false,
  leftSidebar: false,
  footer: true,
  fixedHeader: false,
};

export default function PublicRoute({
  component: Component,
  layout = {},
  ...rest
}: Props) {
  const changeLayout = useActions(actions => actions.layout.change);

  changeLayout({ ...defaultLayout, ...layout });

  // const encloseInErrorBoundary = (props: any) => (
  //   <ErrorBoundary>
  //     <PageTransition>
  //       <Component {...props} />
  //     </PageTransition>
  //   </ErrorBoundary>
  // );

  const encloseComponent = (props: any) => (
      <PageTransition>
        <Component {...props} />
      </PageTransition>
  );

  if (!Component)
    throw new Error(`A component needs to be specified for path ${rest.path}`);

  return <Route {...rest} render={encloseComponent} />;
}
