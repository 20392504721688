import React from "react";
import FormErrorText from "./FormErrorText";
import { ApolloNetworkError } from "../errors/network-error";
import styled from "styled-components";

type Props = {
  error: any;
};

const StyledFormErrorText = styled(({error, ...rest}) => <FormErrorText {...rest} />)<{error: any}>`
  text-align: center;
  opacity: ${p => p.error ? 1 : 0}; 
`;

export const ApolloFormError = ({ error }: Props) => (
  <StyledFormErrorText error={error}>
    <ApolloNetworkError error={error} />
  </StyledFormErrorText>
);
