import gql from 'graphql-tag';

export const CREATE_COURSE = gql`
         mutation createCourse($input: CreateCourse) {
           createCourse(input: $input) {
             id
             name
             price
             url
           }
         }
       `;