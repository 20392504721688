import React from 'react';
import { ApolloError } from 'apollo-client';

type Props = {
    error: any
}

export function apolloNetworkError(error: any){
    return (
        error && 
        error.networkError &&
        error.networkError.result &&
        error.networkError.result.errors[0].message) || "Error";
}

export const ApolloNetworkError = ({error}: Props) => apolloNetworkError(error);
