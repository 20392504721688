import React from "react";
import ApolloClient, { DefaultOptions } from "apollo-client";
import { InMemoryCache, IntrospectionFragmentMatcher  } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { ApolloLink, concat } from "apollo-link";
import { onError } from "apollo-link-error";
import log from "utils/log";
import { ApolloProvider as Provider } from "@apollo/react-hooks";
import { ReactNode } from "react";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { loggerLink } from "./ApolloLogger";
import { setContext } from "apollo-link-context";
import possibleTypes from 'graphql/generates/introspection-result';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "cache-and-network",
    errorPolicy: "all"
  },
  query: {
    //fetchPolicy: 'cache-and-network',
    errorPolicy: "all"
  },
  mutate: {
    errorPolicy: "all"
  }
};

type Props = {
  children: ReactNode;
};

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: possibleTypes,
});

const ApolloProvider = ({ children }: Props) => {
  const cache = new InMemoryCache({
    dataIdFromObject: object => (object as any).id || null,
    fragmentMatcher,
  });

  const handleError = onError(
    ({ graphQLErrors, networkError, response, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, name, extensions }) => {
          log.e(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (extensions && (extensions as any).code === "NO_AUTH") {
          }
        });
      }

      if (networkError) {
        log.e(`[Network error]: ${networkError}`, operation.operationName);
      }

    }
  );

  // const httpLink = new HttpLink({
  //   uri: this.props.graphqlURL,
  //   fetchOptions: {
  //     agent: new Agent({
  //       rejectUnauthorized: false,
  //     }),
  //   },
  // });

  const afterwareJwtLink = new ApolloLink((operation, forward) => {
    return forward!(operation).map(response => {
      const context = operation.getContext();
      const { response: { headers } } = context;
  
      if (headers) {
        const jwt = headers.get('Jwt');
        if(jwt) {
          localStorage.setItem("Jwt" ,jwt);
        }          
      }
  
      return response;
    });
  });

  const beforewareJwtLink = setContext((_, { headers }) => {
    const jwt = localStorage.getItem("Jwt");
    if (jwt) {
      if (headers) {
        headers["Authorization"] = "Bearer " + jwt;
      } else {
        headers = { "Authorization": "Bearer " + jwt };
      }
    }

    return { headers };
  });

  console.log("process.env.REACT_APP_API_GRAPHQL:", process.env.REACT_APP_API_GRAPHQL)
  console.log("process.env.REACT_APP_API_GRAPHQL_WS:", process.env.REACT_APP_API_GRAPHQL_WS)

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_GRAPHQL
    //credentials: "same-origin"
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_API_GRAPHQL_WS as string,
    options: {
      reconnect: true,
      reconnectionAttempts: 10
    }
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  function getCookie(name: string) {
    function escape(s: string) {
      return s.replace(/([.*+?\^${}()|\[\]\/\\])/g, "\\$1");
    }
    var match = document.cookie.match(
      RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)")
    );
    return match ? match[1] : null;
  }

  const authLink = setContext((_, { headers }) => {
    const cxrf = getCookie("XSRF-TOKEN");
    //log.d("cxrf:" + cxrf, headers)
    if (cxrf) {
      if (headers) {
        headers["X-XSRF-Token"] = cxrf;
      } else {
        headers = { "X-XSRF-Token": cxrf };
      }
    }

    return { headers };
  });

  const client = new ApolloClient({
    defaultOptions: defaultOptions,
    link: ApolloLink.from([
      loggerLink,
      handleError,
      beforewareJwtLink,
      afterwareJwtLink,
      link,
      
    ]),
    cache: cache,
    connectToDevTools: true
  });

  return <Provider client={client}>{children}</Provider>;
};

export default ApolloProvider;
