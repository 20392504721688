import { ApolloLink, Observable, Operation } from "apollo-link";
import { getOperationAST } from "graphql";

const elapsed = (startTime: number) => new Date().getTime() - startTime;

const queryStatus: Record<string, string> = {
  loading: "#f4f403",
  success: "#03f42f",
  error: "#f40303",
  default: "lightgrey"
};

const operationType: Record<string, string> = {
  query: "#03A9F4",
  mutation: "#bc02f5",
  subscription: "#f46f03",
  default: "lightgrey"
};

type LogProps = {
  operation: Operation;
  type?: string;
  status?: string;
  startTime?: number;
  response?: any;
  variables?: any;
};

const formatter = ({ operation, type, status, startTime }: LogProps): any[] => {
  const headerCss = [
    "color: gray; font-weight: lighter", // title
    `color: ${queryStatus[status!] || queryStatus.default};`,
    `color: ${operationType[type!] || operationType.default};`,
    "color: inherit;" // operationName
  ];

  const parts = [
    `%capollo`,
    `%c${status ?? "???"}`,
    `%c${type}`,
    `%c${operation.operationName}`
  ];

  if (startTime /*operationName !== "subscription"*/) {
    parts.push(`%c(in ${elapsed(startTime)} ms)`);
    headerCss.push("color: gray; font-weight: lighter;"); // time
  }

  return [parts.join(" "), ...headerCss];
};

const log = ({ response, operation, ...rest }: LogProps) => {
  console.group(...formatter({ operation, ...rest }));
  const variables = operation.variables;
  if (!variables || Object.keys(variables).length === 0) {
    console.debug("%cno variables", "color:grey; font-weight: lighter;");
  } else {
    console.debug("%cvariables\n", "color:#b0a186; font-weight: lighter;", variables);
  }
  if (response) {
    console.debug("%cresponse\n", "color:#86b08c; font-weight: lighter;", response);
  }
  console.groupEnd();
};

export const loggerLink = new ApolloLink((operation, forward) => {
  const startTime = new Date().getTime();

  const ast = getOperationAST(operation.query, operation.operationName);

  const isSubscription = !!ast && ast.operation === "subscription";

  const type = ast?.operation;

  if (!isSubscription) log({ operation, type, status: "loading" });

  if (!forward) return null;
  else
    return new Observable(observer => {
      if (isSubscription) log({ operation, type, status: "subscribe" });

      const sub = forward(operation).subscribe({
        next: response => {
          log({ response, operation, type, status: "success", startTime });
          observer.next(response);
        },
        error: err => {
          log({ response: err, operation, type, status: "error", startTime });
          observer.error(err);
        },
        complete: observer.complete.bind(observer)
      });

      return () => {
        if (isSubscription) log({ operation, type, status: "unsubscribe" });
        sub.unsubscribe();
      };
    });
});
