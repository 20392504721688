import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import Link, { LinkProps } from "@material-ui/core/Link";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.grey[500]
  }
}));

export default function LinkWrap(props: LinkProps & RouterLinkProps) {
  const classes = useStyles();
  return (
    <Link
      {...props}
      className={clsx(classes.link, props.className)}
      component={RouterLink as React.ElementType}
    />
  );
}
