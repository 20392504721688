import {thunk, action, Action, Thunk} from 'easy-peasy';

interface ModalModel {
    isOpen: boolean,
    open: Action<ModalModel>,
    close: Action<ModalModel>,
}

export type LoginModalModel = ModalModel

export const loginModal: LoginModalModel = {
    isOpen: false,
    open: action(state => { state.isOpen=true }),
    close: action(state => { state.isOpen=false }),
};

export type CreateCourseModalModel = ModalModel

export const createCourseModal: CreateCourseModalModel = {
    isOpen: false,
    open: action(state => { state.isOpen=true }),
    close: action(state => { state.isOpen=false }),
};

export type CreateSchoolModalModel = ModalModel

export const createSchoolModal: CreateSchoolModalModel = {
    isOpen: false,
    open: action(state => { state.isOpen=true }),
    close: action(state => { state.isOpen=false }),
};