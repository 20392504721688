import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import * as Yup from "yup";
import AppDialog from "components/shared/dialog/AppDialog";
import { useMutation } from "@apollo/react-hooks";
import useReactRouter from "use-react-router";
import { CREATE_COURSE } from "graphql/mutations/createCourse";
import {
  createCourseVariables,
  createCourse
} from "graphql/generated/types/CreateCourse";
import { Url } from "Constants";
import FormLayout from "components/templates/FormLayout";
import Divider from "@material-ui/core/Divider/Divider";
import SubmitButton from "components/shared/buttons/SubmitButton";
import H6 from "components/shared/typography/H6";
import { CreateCourse } from "graphql/generated/types/globalTypes";
import { ExecutionResult } from "@apollo/react-common";
import { makeValidate } from "components/shared/form/final-form/Validators";
import { Form } from "react-final-form";
import { FormTextField } from "components/shared/form/final-form/FormTextField";
import { useStore, useActions } from "store";
import FormErrorText from "components/shared/typography/FormErrorText";
import { ApolloNetworkError } from "components/shared/errors/network-error";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  submit: {
    marginTop: theme.spacing(3)
  }
}));

const initialCourse: CreateCourse = {
  name: ""
};

const schema = Yup.object<CreateCourse>().shape({
  name: Yup.string().required("Inserire nome")
});

const CreateCourseModal = () => {
  const classes = useStyles();
  const isOpen = useStore(store => store.createCourseModal.isOpen);
  const close = useActions(actions => actions.createCourseModal.close)

  const [createCourse, { loading, error }] = useMutation<
    createCourse,
    createCourseVariables
  >(CREATE_COURSE);
  const history = useHistory();

  const onSubmit = async (values: CreateCourse) => {
    const variables = { variables: { input: values } };
    const res = await createCourse(variables);
    const url =
      Url.Course.Edit.edit[0] +
      (res as ExecutionResult<createCourse>)!.data!.createCourse!.url;
    history.push(url);
    close();
  };

  return (
    <AppDialog open={isOpen} onClose={() => close()}>
      <Form
        initialValues={initialCourse}
        validate={makeValidate<CreateCourse>(schema)}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, errors, submitting }) => (
          <FormLayout onSubmit={handleSubmit}>
            <H6 gutterBottom>
              Inserisci il nome del corso
            </H6>
            <Divider />
            <FormTextField<CreateCourse>
              label="Name"
              name="name"
              autoFocus
              fullWidth
            />
            {error && (
              <FormErrorText>
                <ApolloNetworkError error={error} />
              </FormErrorText>
            )}
            <SubmitButton
              className={classes.submit}
              fullWidth
              loading={submitting}
              disabled={submitting}
              type="submit"
            >
              Crea
            </SubmitButton>
          </FormLayout>
        )}
      </Form>
    </AppDialog>
  );
};

export default CreateCourseModal;
