import gql from 'graphql-tag';
import {AccountFragment} from '../fragments/User';

export const LOGIN = gql`
   mutation login($input: Login) {
       login(input: $input) {
               ...account
           }
       }
       ${AccountFragment}
`;

export const LOGOUT = gql`
   mutation logout {
      logout
   }
`;

export const RESET_PASSWORD = gql`
mutation resetPassword($input: ResetPassword){
    resetPassword(input: $input)
}
`
export const CREATE_STUDENT = gql`
mutation createStudent($input: CreateStudent){
    createStudent(input: $input){
        id
    }
}`

export const CREATE_TEACHER = gql`
mutation createTeacher($input: CreateTeacher){
    createTeacher(input: $input){
        id
    }
}`

export const UPDATE_STUDENT = gql`
mutation updateStudent($input: UpdateStudent){
    updateStudent(input: $input){
        id
    }
}`

export const UPDATE_TEACHER = gql`
mutation updateTeacher($input: UpdateTeacher){
    updateTeacher(input: $input){
        id
    }
}`

export const DELETE_USER = gql`
mutation deleteUser($input: GUID!){
    deleteUser(input: $input)
}`

export const REGISTER_STUDENT = gql`
mutation registerStudent($input: RegisterStudent){
    registerStudent(input: $input)
}`

export const REGISTER_TEACHER = gql`
mutation registerTeacher($input: RegisterTeacher){
    registerTeacher(input: $input)
}`

export const FORGOT_PASSWORD = gql`
mutation forgotPassword($input: ForgotPassword){
    forgotPassword(input: $input)
}
`
export const CHANGE_PASSWORD = gql`
mutation changePassword($input: ChangePassword){
    changePassword(input: $input)
}
`
export const UPDATE_USER = gql`
mutation updateUser($input: UpdateUser){
    updateUser(input: $input){
        id
    }
}`