import React from "react";
import { Theme, Link } from "@material-ui/core";
import { LinkProps } from "@material-ui/core/Link";
import { DefaultBox, DefaultBoxProps } from "../DefaultBox";
import styled from "styled-components";
import {StyledTypographyProps} from "./Typography";

export type TypographyProps = { box?: DefaultBoxProps } & LinkProps;

const StyledTypography = styled(({color, display, fontWeight, ...props}) => <Link {...props} />)<StyledTypographyProps>`
  color: ${p => p.color};
  display: ${p => p.display};
  font-weight: ${p => p.fontWeight};
`;

export default function LinkText({box, ...props}: TypographyProps) {
  return (
    <StyledTypography {...props} fontWeight={box?.fontWeight} display={box?.display} color={box?.color} />
  );
}
