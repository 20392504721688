import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  makeStyles
} from "@material-ui/core/styles";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { defaultFonts } from "themes/theme-default";
import styled from "styled-components";

const AppInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3)
      }
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.common.white,
      border: "1px solid #18a864",
      fontSize: 16,
      width: "100%",
      padding: "12px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Roboto font.
      fontFamily: defaultFonts,
      "&:focus": {
        borderColor: '#054e3a'
      }
    }
  })
)(InputBase);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap"
    },
    margin: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

const StyledFormHelperText = styled(({ type, visibility, ...rest }) => (
  <FormHelperText {...rest} />
))<{ type: "error" | "info"; visibility: string }>`
  color: ${p =>
    p.type === "error"
      ? p.theme.palette.error.main
      : '#666'};
  visibility: ${p => p.visibility};
`;

type Props = {
  label?: string;
  helperText?: string;
  error?: any;
};
export type CustomTextFieldProps = Props & InputBaseProps;

function TextField({
  label = undefined,
  helperText = undefined,
  error = undefined,
  fullWidth = false,
  ...rest
}: CustomTextFieldProps) {
  const classes = useStyles();
const classDivInput = "containerInput "+classes.margin;
  return (
    <FormControl fullWidth className={classDivInput}>
      {/*label && <InputLabel shrink>{label}</InputLabel>*/}
      <AppInput placeholder={label} {...rest} />

      <StyledFormHelperText
        visibility={helperText ? "visible" : "hidden"}
        type={error ? "error" : "info"}
      >
        {helperText}
      </StyledFormHelperText>
    </FormControl>
  );
}

export default TextField;
