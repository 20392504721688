import React, { useCallback, useRef } from "react";
import * as Yup from "yup";
import { useStore, useActions } from "store";
import { useMutation } from "@apollo/react-hooks";
import { loginVariables } from "graphql/generated/types/Login";
import { user } from "graphql/generated/types/User";
import { LOGIN } from "graphql/mutations/mutationUser";
import { Login } from "graphql/generated/types/globalTypes";
import Ui from "utils/toast-utils";
import H6 from "components/shared/typography/H6";
import AppDialog from "components/shared/dialog/AppDialog";
import { makeStyles } from "@material-ui/styles";
import FormErrorText from "components/shared/typography/FormErrorText";
import Divider from "@material-ui/core/Divider";
import FormLayout from "components/templates/FormLayout";
import { ApolloNetworkError } from "components/shared/errors/network-error";
import { ApolloError } from "apollo-client";
import { FormHookContext, FormHookMethods } from "components/shared/form/form-hook/FormHookContext";
import SubmitButton from "components/shared/buttons/SubmitButton";
import DebugForm from "components/shared/form/DebugForm";
import styled from "styled-components";
import { FormTextField } from "components/shared/form/form-hook/FormTextField";
import {StyledSubmitButton} from "components/shared/styles"
import { ApolloFormError } from "components/shared/typography/ApolloFormError";
import log from "utils/log";

const initialLogin: Login = {
  userName: "",
  password: ""
};

const Title = styled(H6)`
  margin-left: ${p => p.theme.spacing(6)}px;
`;

const schema = Yup.object().shape<Login>({
  userName: Yup.string()
    //.email("Email non valida")
    .required("Inserire username"),
  password: Yup.string().required("Inserire password")
});

const LoginModal = () => {
  const loginSucceed = useActions(actions => actions.auth.loginSucceed);
  const isOpen = useStore(state => state.loginModal.isOpen);
  const closeModal = useActions(state => state.loginModal.close);

  const [login, { loading, error: errorLogin, data }] = useMutation<
    user,
    loginVariables
  >(LOGIN, {
    errorPolicy: "all",
    fetchPolicy: "no-cache"
  });

  const onSubmit = async (values: Login) => {
    const input = { variables: { input: values } };
    localStorage.removeItem("Jwt");
    await login(input)
      .then(res => {
        closeModal();
        loginSucceed((res as any).data!.login!);
      })
      .catch(err => {
        Ui.showErrors(err.message);
      })
  };

  return (
    <AppDialog open={isOpen} onClose={() => closeModal()}>
      <Title gutterBottom>Accedi al tuo account</Title>
      <Divider />
      <FormHookContext
        defaultValues={initialLogin}
        mode="onBlur"
        validationSchema={schema}
      >
        {form => (
          <FormLayout onSubmit={form.handleSubmit(onSubmit)}>
            <FormTextField<Login>
              label="Username"
              name="userName"
              autoFocus
              fullWidth
            />
            <FormTextField<Login>
              label="Password"
              name="password"
              type="password"
              fullWidth
            />

            <ApolloFormError error={errorLogin} />
          
            <SubmitButton
              variant="outlined"
              fullWidth
              loading={form.formState.isSubmitting}
              disabled={form.formState.isSubmitting}
            >
              Login
            </SubmitButton>
          </FormLayout>
        )}
      </FormHookContext>
    </AppDialog>
  );
};

export default LoginModal;
