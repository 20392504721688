import React, { ReactNode } from "react";
import { useStore } from "store";
import LoginPage from "components/pages/login/LoginModal";
import log from "utils/log";
import CreateCourseInitModal from "components/pages/course/create/CreateCourseModal";
import { useLocation, useHistory } from "react-router-dom";
import { Url } from "Constants";
import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

type Props = {
  children: ReactNode;
};

const BodyContent = ({ children }: Props) => {
  const isAuthenticated = useStore(state => state.auth.isAuthenticated);
  let location = useLocation();
  const searchText = useStore(store => store.search.text);
  const searchLoading = useStore(store => store.search.loading);
  let history = useHistory();
  // log.d("BodyContent REFRESH")
  useEffect(() => {
    const isSearching = location.pathname.includes(Url.Course.search);

    if (!isSearching && searchText && searchLoading)
      history.push(Url.Course.search);
    else if (isSearching && !searchText) history.push(Url.home);
  }, [location, history.push, searchText, searchLoading]);

  return (
    <>
      {children}
      <AnimatePresence>
        {!isAuthenticated && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoginPage />
          </motion.div>
        )}
        {isAuthenticated && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <CreateCourseInitModal />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default BodyContent;
